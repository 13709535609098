<template>
  <div class="w-full">
    <!-- LOADER -->
    <Loader v-show="loadingPost" class="w-full h-sidebar rounded-lg" no-border/>
    <!-- POST -->
    <div class="w-full rounded-lg gap-y-6 gap-x-6 bg-white">
      <div class="w-full flex flex-col lg:flex-row px-3 pt-3 lg:px-6 lg:pt-6 gap-6" v-if="postData">
        <!-- DESKTOP -->
        <div class="hidden lg:block flex lg:w-1/2 h-post-img max-h-post-img">
          <div class="flex flex-col max-h-post-img h-post-img content-end">
            <div class="flex flex-col h-max content-end">
              <!-- TITLE -->
              <div class="text-black font-semibold leading-tight text-lg">
                {{ excerpt(postData.title, 85) }}
              </div>
              <!-- SEPARTOR LINE -->
              <hr class="mt-3 mb-2 border-grayed border-dashed bg-white"/>
              <div class="flex flex-row">
                <!-- AVAILABLE IN LANGUAGES -->
                <div class="w-full flex flex-row text-black text-s gap-2 justify-start" v-if="getLang !== postData.languageCode">
                  <span>{{ trans('LABEL:ArticleAvailableInLanguages') }}:</span>
                  <div class="flex flex-row gap-3">
                    <img v-for="(flag) in postData.articleAvailableInLanguages" :src="require(`@/assets/images/flags/${flag}.png`)" alt="flag" class="rounded-full border border-black h-4 w-4">
                  </div>
                </div>
                <!-- CATEGORY + PUBLISHED AT -->
                <div class="w-full flex flex-row text-grayed justify-end items-center">
                    <span class="text-redAccent text-s"
                          :class="{'hover:opacity-50': !isCurrentRoute(postData.postCategorySlug)}"
                          @click.stop="!isCurrentRoute(postData.postCategorySlug) ? goToCategory(postData.postCategorySlug) : null"
                    >
                      {{ trans(`POST_CATEGORY:${postData.postCategorySlug}`) }}
                    </span>
                  &nbsp;|&nbsp;<span class="text-black text-s">{{ postData.publishedAt }}</span>
                </div>
              </div>
            </div>
            <div class="my-auto"></div>
            <!-- EXCERPT -->
            <div class="font-semibold leading-snug text-base text-black mt-3">
              <span v-html="postData.excerpt"/>
            </div>
          </div>
        </div>
        <!-- PICTURE -->
        <div class="w-full lg:w-1/2 lg:h-post-img lg:max-h-post-img rounded-lg border border-grayed"
             :class="[getHeightOfScreenWidth(0.75,reducedPictureOffset?24:48)]"
             :style="[{background: 'url(' + postData.image + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}]"
        />
        <!-- MOBILE -->
        <div class="lg:hidden flex flex-col w-full">
          <div class="flex flex-col">
            <!-- TITLE -->
            <div class="text-black font-semibold leading-tight text-lg">
              {{ excerpt(postData.title, 85) }}
            </div>
            <!-- SEPARTOR LINE -->
            <hr class="mt-3 mb-2 border-grayed border-dashed bg-white"/>
            <div class="flex flex-row">
              <!-- AVAILABLE IN LANGUAGES -->
              <div class="w-full flex flex-row text-black text-s gap-2 justify-start" v-if="getLang !== postData.languageCode">
                <span>{{ trans('LABEL:ArticleAvailableInLanguages') }}:</span>
                <div class="flex flex-row gap-3">
                  <img v-for="(flag) in postData.articleAvailableInLanguages" :src="require(`@/assets/images/flags/${flag}.png`)" alt="flag" class="rounded-full border border-black h-4 w-4">
                </div>
              </div>
              <!-- CATEGORY + PUBLISHED AT -->
              <div class="w-full flex flex-row text-grayed justify-end items-center">
                  <span class="text-redAccent text-s"
                        :class="{'hover:opacity-50': !isCurrentRoute(postData.postCategorySlug)}"
                        @click.stop="!isCurrentRoute(postData.postCategorySlug) ? goToCategory(postData.postCategorySlug) : null"
                  >
                    {{ trans(`POST_CATEGORY:${postData.postCategorySlug}`) }}
                  </span>
                &nbsp;|&nbsp;<span class="text-black text-s">{{ postData.publishedAt }}</span>
              </div>
            </div>
          </div>
          <!-- EXCERPT -->
          <div class="font-semibold leading-snug text-base text-black mt-3">
            <span v-html="postData.excerpt"/>
          </div>
        </div>
      </div>
      <!-- Post excerpt and text -->
      <div v-if="postLoaded && postData"
           class="bg-white text-base lg:mt-3 px-3 lg:px-6 pb-3 lg:pb-6 rounded-b-md space-y-4"
           :class="{'mb-1.5':!isMobile && postData.postCategorySlug!=='contact'}"
      >
        <hr class="border-transparent"/>
        <div class="text-base ql-content">
          <span class="post-body" v-html="postData.body"></span>
        </div>
      </div>
    </div>
    <!-- Contact form -->
    <template v-if="postData && postData.postCategorySlug === 'contact' && !loadingPost">
      <t-card variant="contact">
        <hr class="-mx-6 -mt-3 border-grayed border-dashed bg-white"/>
        <ContactForm :type="'CONTACT_FORM'" />
      </t-card>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import Button from "@/utils/crud/components/Button";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import Comments from "@/views/content/components/Comments";
import CommentForm from "@/views/content/components/CommentForm";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Loader from "@/views/Loader";
import router from "@/router";
import PostCard from "@/views/content/posts/components/PostCard";
import ContactForm from "@/views/content/components/ContactForm";
import {getHeightOfScreenWidth} from "@/common/helpers/utils";
import {excerpt} from "@/common/helpers/utils";

export default {
  name: "PostShow",
  components: {
    Button,
    CommentForm,
    Comments,
    ContactForm,
    Loader,
    PageContainer,
    PageTitle,
    PostCard,
  },
  data() {
    return {
      isMobile: checkIsMobileDevice(),
      excerpt:excerpt,
      getHeightOfScreenWidth: getHeightOfScreenWidth,
    }
  },
  props: {
    slug: String,
    reducedPictureOffset: Boolean,
  },
  watch: {
  },
  computed: {
    ...mapGetters('posts', ['postData', 'postLoaded', 'loadingPost']),
    ...mapGetters('loader', ['loading']),
    ...mapGetters('account', ['userData']),
    ...mapGetters('lang', ['langLocale']),

    getLang() {
      if(this.userData) {
        return this.userData.lang;
      }
      return this.langLocale;
    }
  },
  methods: {
    ...mapActions('posts', ['fetchPostShowRequest']),
    ...mapMutations('posts', ['setPostData', 'setCurrentOnlyPostSlug']),

    goToPostCategory(slug) {
      router.push({name: 'posts', params: {postCategory: slug}});
    },
    isCurrentRoute(slug) {
      return this.$route.params.postCategory === slug;
    },
  },
  mounted() {
    this.fetchPostShowRequest({slug: this.slug});
  },
  beforeDestroy() {
    this.setPostData(null);
    this.setCurrentOnlyPostSlug(null);
  },
  destroyed() {

  }
}
</script>

<style lang="scss">
.h-post-screen {
  height: 78vh;
}
@media (min-width: 1024px) {
  .h-post-screen {
    height: 88vh;
  }
}

</style>
